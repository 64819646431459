import React from "react"
import styles from "./blockBgTitle.module.css"

const BlockBgTitle = ({ bgColor, title }) => {
  return (
    <section className={styles.blockBgTitle}>
      <div className={`${styles.blockBgTitle__text} font-cream-light`}>
        <h1>{title}</h1>
      </div>
    </section>
  )
}
export default BlockBgTitle
