import { useStaticQuery, graphql } from "gatsby"

export const useStrategicPage = () => {
  const { allNodeStrategicConsulting } = useStaticQuery(
    graphql`
      query StrategicConsultingPage {
        allNodeStrategicConsulting {
          edges {
            node {
              field_bg_title
              field_textbig_text_ {
                processed
              }
              field_margintextimageleft2_text_
              field_margintextimageleft2_title
              field_margintextimageleft_text_ {
                processed
              }
              field_margintextimageleft_title_
              field_margintextimageright2_text {
                processed
              }
              field_margintextimageright2_titl
              field_margintextimageright_title
              field_margintextimageright_text_ {
                processed
              }
              field_text_center_text_ {
                processed
              }
              field_text_center_title_
              field_text_image_left_text_
              field_text_image_left_title_
              field_textbig_text_ {
                processed
              }
              field_two_columns_col_1_text_ {
                processed
              }
              field_two_columns_col_1_title_
              field_two_columns_col_2_text_ {
                processed
              }
              field_two_columns_col_2_title_
              relationships {
                field_margintextimageleft2_img_ {
                  uri {
                    url
                  }
                }
                field_margintextimageleft_img_ {
                  uri {
                    url
                  }
                }
                field_margintextimageright2_img_ {
                  uri {
                    url
                  }
                }
                field_margintextimageright_img_ {
                  uri {
                    url
                  }
                }
                field_text_image_left_img_ {
                  uri {
                    url
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return allNodeStrategicConsulting.edges[0].node
}
