import React from "react"

import FullScreenMenu from "../components/FullScreenMenu/FullScreenMenu"
import Topbar from "../components/Topbar/Topbar"
import BlockBgTitle from "../components/BlockBgTitle/BlockBgTitle"
import BlockTwoColumns from "../components/BlockTwoColumns/BlockTwoColumns"
import BlockTextImageLeft from "../components/BlockTextImageLeft/BlockTextImageLeft"
import BlockMarginTextImageRight from "../components/BlockMarginTextImageRight/BlockMarginTextImageRight"
import BlockMarginTextImageLeft from "../components/BlockMarginTextImageLeft/BlockMarginTextImageLeft"
import BlockTextBig from "../components/BlockTextBig/BlockTextBig"
import BlockTextCenter from "../components/BlockTextCenter/BlockTextCenter"

import Footer from "../components/Footer/Footer"

import { useStrategicPage } from "../hooks/use-strategicPage"

const StrategicConsulting = () => {
  const strategicData = useStrategicPage()
  const bgTitleData = strategicData.field_bg_title
  const twoColumnsData = {
    titleOne: strategicData.field_two_columns_col_1_title_,
    textOne: strategicData.field_two_columns_col_1_text_,
    titleTwo: strategicData.field_two_columns_col_2_title_,
    textTwo: strategicData.field_two_columns_col_2_text_,
  }
  const textImageLeft = {
    title: strategicData.field_text_image_left_title_,
    text: strategicData.field_text_image_left_text_,
    relationships: strategicData.relationships,
  }
  const marginTextImageLeft1 = {
    title: strategicData.field_margintextimageleft_title_,
    text: strategicData.field_margintextimageleft_text_.processed,
    img: strategicData.relationships.field_margintextimageleft_img_,
  }
  const marginTextImageLeft2 = {
    title: strategicData.field_margintextimageleft2_title,
    text: strategicData.field_margintextimageleft2_text_,
    img: strategicData.relationships.field_margintextimageleft2_img_,
  }
  const blockMarginTextImageRight1 = {
    title: strategicData.field_margintextimageright_title,
    text: strategicData.field_margintextimageright_text_.processed,
    img: strategicData.relationships.field_margintextimageright_img_,
  }
  const blockMarginTextImageRight2 = {
    title: strategicData.field_margintextimageright2_titl,
    text: strategicData.field_margintextimageright2_text.processed,
    img: strategicData.relationships.field_margintextimageright2_img_,
  }
  const textCenterData = {
    title: strategicData.field_text_center_title_,
    text: strategicData.field_text_center_text_.processed,
  }

  console.log(strategicData.field_textbig_text_.processed)

  return (
    <div className="theContainer">
      <FullScreenMenu />
      <div id="container" className="container">
        <Topbar />
        <BlockBgTitle title={bgTitleData} />
        <BlockTwoColumns data={twoColumnsData} />
        <BlockTextImageLeft data={textImageLeft} />
        <BlockMarginTextImageRight data={blockMarginTextImageRight1} />
        <BlockTextCenter titleColor="font-grey" data={textCenterData} />
        <BlockMarginTextImageLeft data={marginTextImageLeft1} />
        <BlockMarginTextImageRight data={blockMarginTextImageRight2} />
        <BlockMarginTextImageLeft data={marginTextImageLeft2} />
        {/* <BlockTextBig
          bgColor="bg-cream-light"
          fontColor="font-grey"
          text={strategicData.field_textbig_text_.processed}
        /> */}
        <Footer />
      </div>
    </div>
  )
}

export default StrategicConsulting
